import { useSession } from "@/hooks/features/useSession";
import { Inter } from "@next/font/google";
import { useRouter } from "next/router";

const inter = Inter({ subsets: ["latin"] });

export default function Lp() {
  const { session } = useSession();
  const router = useRouter();
  if (!session) {
    void router.push("/sign-in");
  } else {
    void router.push("/sign-in/after-sign-in-process");
  }
  return <></>;
}
